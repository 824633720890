.CreateItem-info-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.CreateItem-form {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.CreateItem-btn-div {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
    margin-top: 20px;
}