.AddSupplier-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.AddSupplier-form-div {
    display: flex;
    align-items: center;
    width: 600px;
    justify-content: space-between;
}

.AddSupplier-form-label {
    width: 200px;

}

.AddSupplier-form-input {}

.AddSupplier-btn-Container {

    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin: 20px 0px 10px 0px;

}

.AddCustomer-add-bt {
    background-color: var(--color-secondary);
}

.AddCustomer-cancel-btn {
    background-color: var(--warning-color);
}