.pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination li a {
    padding: 8px 16px;
    border: 1px solid #ddd;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
  }
  
  .pagination li a:hover {
    background-color: #f0f0f0;
  }
  
  .pagination li.active a {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  