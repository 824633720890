.UpdateDesignation-search-div {
    margin-bottom: 200px;
}

.UpdateDesignation-search {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 20px;
}

.UpdateDesignation-search-btn {

    width: 600px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--color-primary);
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
    padding-left: 5px;

}

.UpdateDesignation-search-input {
    width: 100%;
    height: 35px;
    border: none;
    background-color: transparent;

    &:focus {
        outline: 0px solid #ddd;
    }
}

.UpdateDesignation-search-img-show {
    width: 15px;
    height: 15px;
    transition: .5s;
    margin: 10px;
}

.UpdateDesignation-search-img-hide {
    width: 15px;
    height: 15px;
    rotate: 180deg;
    transition: .5s;
    margin: 10px;
}

.UpdateDesignation-search-result_div-show {
    background-color: var(--color-primary);
    width: 600px;
    position: absolute;
    z-index: 1;

    height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1px;

}

.UpdateDesignation-search-result_div-hide {
    display: none;
}

.UpdateDesignation-search-result-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;

    &&:hover {
        background-color: var(--color-secondary);
    }
}









.UpdateDesignation-info-div {}

.UpdateDesignation-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto;
}

.UpdateDesignation-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    margin: 10px 0px;
}







.UpdateDesignation-btn-div {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
    margin-top: 20px;
}

/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .input-container {
    margin-bottom: 15px;
  }
  
  .input-container label {
    display: block;
    font-weight: bold;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .designation-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .designation-table th,
  .designation-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .designation-table th {
    background-color: #f2f2f2;
    text-align: left;
  } */
  