/* Main container styling for the custom time picker */
.custom-time-picker {
    font-size: 18px; /* Base font size for consistency */
    color: #333; /* Darker text color for contrast */
    padding: 10px; /* Padding around the component */
    background-color: #ffffff; /* White background for the picker */
}

/* Styling for the input field within the custom time picker */
.custom-time-picker input {
    color: #333; /* Text color */
    background-color: #f9f9f9; /* Light gray background for contrast */
    font-size: 18px; /* Increased font size for readability */
    padding: 10px; /* Padding around the text */
    border: 1px solid #ccc; /* Border to define input box */
    border-radius: 5px; /* Rounded corners */
    outline: none; /* Removes default outline on focus */
    width: 100%; /* Full width to fit container */
    box-sizing: border-box; /* Ensures padding doesn't increase input size */
}

/* Wrapper styling for react-time-picker to add consistent borders */
.custom-time-picker .react-time-picker__wrapper {
    border: 1px solid #ccc; /* Light border for consistency */
    border-radius: 5px; /* Rounding for uniformity */
    padding: 2px; /* Inner padding for alignment */
}

/* Styling individual input groups within the time picker */
.custom-time-picker .react-time-picker__inputGroup__input {
    font-size: 18px; /* Consistent font size */
    color: #333; /* Text color */
    padding: 5px; /* Padding for alignment */
    margin: 0; /* Removes default margins */
    background-color: #f9f9f9; /* Matches input background color */
    border: none; /* Removes inner borders */
    border-radius: 3px; /* Slight rounding for each group */
}

/* Ensures leading zeros are visible in each part of the time picker */
.custom-time-picker .react-time-picker__inputGroup__leadingZero {
    color: #333; /* Ensures consistency with other text */
}

/* Hover and focus effects for a more interactive experience */
.custom-time-picker input:hover, 
.custom-time-picker .react-time-picker__wrapper:hover {
    border-color: #888; /* Darker border on hover */
}

.custom-time-picker input:focus, 
.custom-time-picker .react-time-picker__inputGroup__input:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); /* Slight shadow on focus */
}

/* Placeholder color for a consistent, subtle hint */
.custom-time-picker ::placeholder {
    color: #999; /* Gray placeholder text */
    opacity: 1; /* Ensures placeholder text is fully opaque */
}
