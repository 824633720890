.InventoryRequest-request-div {}


.InventoryRequest-request-info-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;


    div {
        display: flex;
        width: 600px;
        align-items: center;
        justify-content: space-between;
    }
}

.InventoryRequest-request-search-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px;
    padding: 50px 10px;

}

.InventoryRequest-search {
    display: flex;
    align-items: start;
    gap: 20px;
    width: 600px;
    justify-content: space-between;
}

.InventoryRequest-search-main {
    display: flex;
    flex-direction: column;

}

.InventoryRequest-search-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 400px;
    height: 35px;
    background-color: var(--color-primary);
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
    padding: 0px 5px;
}

.InventoryRequest-search-input {
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: none;

    &:focus {
        outline: 0px solid #ddd;
    }
}

.InventoryRequest-search-img-show {
    width: 15px;
    height: 15px;
    transform: rotate(180deg);
    transition: 0.3s;
}

.InventoryRequest-search-img-hide {

    width: 15px;
    height: 15px;
    transition: 0.3s;
}

.InventoryRequest-search-result-div-show {
    width: 400px;
    height: 170px;
    overflow-y: scroll;
    background-color: var(--color-primary);
    position: absolute;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    z-index: 1;

    button {
        background-color: #f8f8f8;
        border: none;
        height: 25px;

        &:hover {
            background-color: var(--color-secondary);
        }
    }
}

.InventoryRequest-search-result-div-hide {
    display: none;
}







.InventoryRequest-request-table-div {}

.InventoryRequest-request-btn-div {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
    margin-top: 20px;
}

.InventoryRequest-request-table-input {
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px
}

.InventoryRequest-request-table-delete-btn {
    background-color: rgb(239, 181, 181);
    padding: 5px 10px;
    border-radius: 4px;

    &:hover {
        background-color: rgb(218, 22, 22);
        color: white;
    }
}

.InventoryRequest-request-t-1 {
    width: 5%;
}

.InventoryRequest-request-t-2 {
    width: 30%;
}

.InventoryRequest-request-t-3 {
    width: 15%;
}

.InventoryRequest-request-t-4 {
    width: 10%;
}

.InventoryRequest-request-t-5 {
    width: 15%;
}

.InventoryRequest-request-t-6 {
    width: 10%;
}




























.InventoryRequest-job-preview-div {
    position: absolute;
    top: 0%;
    background-color: aliceblue;
    width: 100%;
    min-height: 100vh;
}

.InventoryRequest-transfer-preview-div {
    position: absolute;
    top: 0%;
    background-color: aliceblue;
    width: 100%;
    min-height: 100vh;
}






.InventoryRequest-pos-preview-div {
    position: absolute;
    top: 0%;
    background-color: aliceblue;
    width: 93%;
    min-height: 100vh;
}

.InventoryRequest-pos-preview-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;



    div {
        width: 600px;
        display: flex;
        justify-content: space-between;
    }
}

.InventoryRequest-pos-preview-table {
    margin: 20px auto;
}

.InventoryRequest-pos-preview-btn {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 20px;
}
















.InventoryRequest-items-select-div {
    position: absolute;
    top: 0%;
    background-color: aliceblue;
    width: 100%;
    min-height: 100vh;
}

.InventoryRequest-preview-hide {
    position: absolute;
    top: 0%;
    background-color: aliceblue;
    width: 100%;
    min-height: 100vh;
    display: none;
}