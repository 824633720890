.inventory-store-search-div {
    height: 280px;
}

.inventory-store-po-search-div {
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    gap: 10px;
}

.inventory-store-po-search-btn-div {}

.inventory-store-po-search-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
    background-color: var(--color-primary);
    padding: 0px 5px;
}

.inventory-store-po-search-input {
    height: 30px;
    width: 400px;
    background-color: transparent;
    border: none;

    &:focus {
        outline: 0px solid #ddd;
    }
}

.inventory-store-po-search-img-show {
    width: 25px;
    transition: 0.3s;
}

.inventory-store-po-search-img-hide {
    width: 25px;
    transform: rotate(180deg);
    transition: 0.3s;
}

.inventory-store-po-search-results-div-show {
    background-color: var(--color-primary);
    width: 452px;
    position: absolute;
    z-index: 1;
    height: 140px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1px;

    align-items: center;
    justify-content: start;
}

.inventory-store-po-search-results-div-hide {
    display: none;
}

.InventoryPurchase-po-search-results-btn {
    width: 100%;
    background-color: #f0f0f0;

    &:hover {
        background-color: var(--color-secondary);
    }
}







.inventory-store-search-results-div {}

.inventory-store-search-info-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    margin: 20px auto;
    gap: 10px;
}

.inventory-store-info-div {
    /* background-color: violet; */
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}


.inventory-store-table-div {
    margin: 20px 0px;
}

.InventoryStore-purchase-t-1 {
    width: 4%;
}

.InventoryStore-purchase-t-2 {
    width: 30%;
}

.InventoryStore-purchase-t-3 {
    width: 10%;
}

.InventoryStore-purchase-t-4 {
    width: 10%;
}

.InventoryStore-purchase-t-5 {
    width: 10%;
}

.InventoryStore-purchase-t-6 {
    width: 10%;
}

.InventoryStore-purchase-t-7 {
    width: 10%;
}

.InventoryStore-purchase-t-8 {
    width: 5%;
}

.InventoryStore-purchase-t-9 {
    width: 10%;
}




.inventory-store-btn-div {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}