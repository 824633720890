.pos {}


.pos-info-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
}

.pos-info-div-form {
    /* background-color: aqua; */
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pos-info-div-form-label {
    /* background-color: rgb(1, 43, 43); */
    width: 200px;
}

.pos-info-div-form-input {}







/* customer */
.pos-info-customer-div {
    /* background-color: yellowgreen; */
    display: flex;
    align-items: center;
    width: 350px;

}

.pos-info-customer-search-div {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.pos-info-customer-search-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 35px;
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
    background-color: var(--color-primary);
    padding-left: 5px;
}

.pos-info-customer-search-input {
    width: 100%;
    border: none;
    background-color: transparent;
    height: 23px;

    &:focus {
        outline: 0px solid #ddd;
    }
}

.pos-select-customer-search-dropdown-img-on {
    width: 15px;
    height: 15px;
    transition: .5s;
    margin: 10px;
}

.pos-select-customer-search-dropdown-img-off {
    width: 15px;
    height: 15px;
    rotate: 180deg;
    transition: .5s;
    margin: 10px;
}



.pos-info-customer-search-content-hide {
    display: none;
}

.pos-info-customer-search-content-show {
    position: absolute;
    z-index: 1;
    margin-top: 35px;
    background-color: var(--color-primary);
    width: 20.9%;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.pos-info-customer-dropdown-select-btn {
    width: 100%;
    background-color: #ddd;

    &:hover {
        background-color: var(--color-secondary);
    }
}

.pos-info-dropdown-select-btn {
    width: 100%;

}





.pos-info-customer-add-div {}

.pos-info-customer-add-btn {
    width: 30px;
    height: 30px;
    /* background-color: transparent; */
    border: none;
    font-size: 27px;
    /* background-color: violet; */
    display: flex;
    justify-content: center;
    align-items: center;
}










.pos-search-div {
    /* background-color: aqua; */
    width: 100%;
    padding: 20px 0px 140px 0px;
}

.pos-search-div-sub {
    width: 100%;
}

.pos-search-div-1-label {}

.pos-search-div-1-select {
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.pos-search-div-1-select-drop-down-btn {
    width: 86vw;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--color-primary);
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
    padding-left: 5px;
}

.pos-search-div-1-select-input {
    width: 100%;
    height: 35px;
    border: none;
    background-color: transparent;

    &:focus {
        outline: 0px solid #ddd;
    }
}

.pos-select-dropdown-img-on {
    width: 20px;
    height: 20px;
    transition: 0.3s;
    margin: auto 10px;
}

.pos-select-dropdown-img-off {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    transition: 0.3s;
    margin: auto 10px;
}

.pos-dropdown-content-hide {
    display: none;
}

.pos-dropdown-content-show {
    background-color: var(--color-primary);
    width: 86vw;
    position: absolute;
    z-index: 1;
    margin-top: 240px;
    height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1px;



}


.pos-dropdown-select-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: none;

    &&:hover {
        background-color: var(--color-secondary);
    }

}

.pos-dropdown-select-btn-p1 {
    font-size: 16px;
    line-height: 0px;
    /* background-color: aqua; */
    max-width: 65vw;
    height: 20px;
    /* overflow-x: hidden; */
    display: flex;
    align-items: center;
    margin: 0px;

    &&:hover {
        max-width: 90vw;
        z-index: 1;

    }
}

.pos-dropdown-select-btn-p2 {
    line-height: 0px;
    background-color: aqua;
    width: 600px;
}

.pos-dropdown-select-btn-p3 {
    line-height: 0px;
}








.pos-table-div {
    padding: 20px 0px;
}

.pos-table-input-red {
    border: 2px solid red;

    &:focus {
        outline: 2px solid red;
    }



}

.pos-table-input {
    outline: 1px solid black;
    width: 90px;
}

.pos-table-action-btn {
    background-color: rgb(239, 181, 181);
    padding: 5px 10px;
    border-radius: 4px;

    &:hover {
        background-color: rgb(218, 22, 22);
        color: white;
    }
}








.pos-total-div {

    display: flex;
    justify-content: end;
    /* background-color: yellowgreen; */

}

.pos-total-div-main {
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    padding: 10px;
    margin: 10px 0px;
    /* background-color: violet; */
    gap: 15px;


}

.pos-total-sub-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;




}

.pos-total-sub-div-p {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 10px;
}

.pos-total-sub-div-input {
    height: 20px;
    width: 100px;
    background-color: var(--color-primary);
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
}






.pos-btn-div {
    display: flex;
    justify-content: end;
    gap: 10px;
}



.pos-btn-div .btn-enter {
    background-color: rgb(0, 255, 119);
}




.pos-add-customer-show {
    position: absolute;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.929);
    height: 100vh;
    z-index: 2;
    animation: slideIn 0.5s forwards;
}

.pos-add-customer-hide {
    position: absolute;
    top: 0px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.929);


    animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
        opacity: 1;

    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
        display: none;
    }
}






















.pos-bill {
    width: 100%;
    height: 100vh;



    padding: 10px 10px 10px 10px;
}

.pos-bill-header {
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.pos-bill-p1 {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
}

.pos-bill-p1-1 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
}

.pos-bill-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0px;
}

.pos-bill-info-sub {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 350px;
    margin: auto 20px;
}

.pos-bill-p2 {
    font-size: 20px;

}


.pos-bill-table-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

}

.pos-bill-table-head {
    display: flex;
    align-items: center;
    width: 100%;

    border: 1px solid black
}

.pos-bill-table-p1 {
    width: 5%;

    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    /* background-color: aliceblue; */
    border-right: 1px solid black;
}

.pos-bill-table-p2 {
    width: 50%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    /* background-color: rgb(17, 106, 185); */
    border-right: 1px solid black;
}

.pos-bill-table-p3 {
    width: 15%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    /* background-color: rgb(207, 190, 34); */
    border-right: 1px solid black;
}

.pos-bill-table-p4 {
    width: 15%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    /* background-color: rgb(191, 29, 216); */
    border-right: 1px solid black;
}

.pos-bill-table-p5 {
    width: 7%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    /* background-color: rgb(208, 22, 68); */
    border-right: 1px solid black;
}

.pos-bill-table-p6 {
    width: 15%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    /* background-color: rgb(6, 34, 247); */
}


.pos-bill-table-body {
    display: flex;
    align-items: center;

    border: 1px solid black;
    border-top: none;
    width: 100%;
}

.pos-bill-table-p7 {
    width: 5%;
    text-align: center;
    font-size: 18px;

    padding: 5px 0px;
    /* background-color: aliceblue; */
    border-right: 1px solid black;
    height: 35px;
}

.pos-bill-table-p8 {
    width: 50%;
    text-align: center;
    font-size: 18px;
    height: 35px;

    padding: 5px 0px;
    /* background-color: rgb(17, 106, 185); */
    border-right: 1px solid black;
}

.pos-bill-table-p9 {
    width: 15%;
    text-align: center;
    font-size: 18px;

    padding: 5px 0px;
    /* background-color: rgb(207, 190, 34); */
    border-right: 1px solid black;
    height: 35px;
}

.pos-bill-table-p10 {
    width: 15%;
    text-align: center;
    font-size: 18px;

    padding: 5px 0px;
    /* background-color: rgb(191, 29, 216); */
    border-right: 1px solid black;
    height: 35px;
}

.pos-bill-table-p11 {
    width: 7%;
    text-align: center;
    font-size: 18px;

    padding: 5px 0px;
    /* background-color: rgb(208, 22, 68); */
    border-right: 1px solid black;
    height: 35px;
}

.pos-bill-table-p12 {
    width: 15%;
    text-align: center;
    font-size: 18px;

    padding: 5px 0px;
    /* background-color: rgb(6, 34, 247); */
    height: 35px;
}










.pos-bill-total-div {
    display: flex;
    justify-content: end;
    margin: 20px auto;
}

.pos-bill-total-div-sub {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    border: 1px solid black;
}

.pos-bill-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}




.pos-bill-footer {}

.pos-bill-footer-p1 {
    text-align: center;
}

























.bill-container {
    width: 80mm;
    max-width: 80mm;
    font-family: Arial, sans-serif;
    font-size: 10px;
    margin: 0 auto;
    padding: 5mm;
    box-sizing: border-box;
}

.header {
    text-align: center;
    margin-bottom: 5mm;
}

.bill-logo {
    max-width: 50mm;
    height: auto;
    display: block;
    margin: 0 auto;
  }

h1 {
    font-size: 14px;
    margin: 5mm 0;
}

hr {
    border: 0;
    border-top: 1px solid #000;
    margin: 2mm 0;
}

.invoice-details,
.customer-details,
.totals,
.footer {
    margin-bottom: 5mm;
}

.product-table {
    width: 100%;
    border-collapse: collapse;
}

.product-table th,
.product-table td {
    border: 1px solid #000;
    padding: 2mm;
    text-align: left;
}

.product-table th {
    background-color: #f2f2f2;
}

.totals {
    text-align: right;
}

.footer {
    text-align: center;
}