.inventory-purchase {
    width: 100%;
}

.InventoryPurchase-po-main-div {
    margin-bottom: 150px;
}

.InventoryPurchase-po-div {
    /* background-color: aqua; */
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 10px;
}

.InventoryPurchase-po-search-div {}

.InventoryPurchase-po-search-bth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
    background-color: var(--color-primary);
    padding-left: 5px;
}

.InventoryPurchase-po-search-input {
    height: 35px;
    width: 400px;
    background-color: transparent;
    border: none;

    &:focus {
        outline: 0px solid #ddd;
    }
}

.InventoryPurchase-po-search-img-show {
    width: 25px;
    margin: auto 5px;
    transition: 0.3s;
}

.InventoryPurchase-po-search-img-hide {
    width: 25px;
    margin: auto 5px;
    transform: rotate(180deg);
    transition: 0.3s;
}

.InventoryPurchase-po-search-results-div-show {
    background-color: var(--color-primary);
    width: 457px;
    position: absolute;
    z-index: 1;
    height: 140px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1px;

    align-items: center;
    justify-content: start;
}

.InventoryPurchase-po-search-results-div-hide {
    display: none;
}

.InventoryPurchase-po-search-results-btn {
    width: 100%;
    height: 25px;
    border: none;
    background-color: #eeeeee;


    &:hover {
        background-color: var(--color-secondary);
    }

}





.InventoryPurchase-po-info-main-div {
    margin: 10px 0px;
}

.InventoryPurchase-po-info-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto;
}

.InventoryPurchase-po-info {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 500px;
    gap: 5px 30px;
    /* background-color: aqua; */

    .label {
        width: 200px;
        /* background-color: yellowgreen; */
    }
}




















.InventoryPurchase-purchase-items-div {
    width: 100%;
    overflow-x: scroll;
}

.InventoryPurchase-purchase-t-1 {
    width: 3%;
    /* background-color: aqua; */
}

.InventoryPurchase-purchase-t-2 {
    width: 25%;
    /* background-color: yellowgreen; */
}

.InventoryPurchase-purchase-t-3 {
    width: 10%;
    /* background-color: violet; */

    input {
        width: 80px;
    }
}

.InventoryPurchase-purchase-t-4 {
    width: 10%;
    /* background-color: antiquewhite; */
}

.InventoryPurchase-purchase-t-5 {
    width: 10%;
    /* background-color: brown; */

    input {
        width: 80%;
    }
}

.InventoryPurchase-purchase-t-6 {
    width: 5%;
    /* background-color: aqua; */
}

.InventoryPurchase-purchase-t-7 {
    width: 10%;
    /* background-color: yellowgreen; */

    input {
        width: 80%;
    }
}

.InventoryPurchase-purchase-t-8 {
    width: 15%;
    /* background-color: turquoise; */

    textarea {
        width: 80%;
    }
}

.InventoryPurchase-purchase-t-9 {
    width: 120px;
    /* background-color: blue; */

    select {
        width: 80%;
    }
}



.formal-document {
    font-family: Arial, sans-serif;
    color: #000;
    background: #fff;
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.document-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.document-section {
    margin-bottom: 20px;
}

.section-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.section-content p {
    font-size: 14px;
    margin: 5px 0;
}

.items-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.items-table th,
.items-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.items-table th {
    background-color: #f2f2f2;
    font-size: 14px;
}

.items-table td {
    font-size: 12px;
}