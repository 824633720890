.AddBranches-main-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 20px auto;
}

.AddBranches-form-div {
    /* background-color: yellowgreen; */
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}




.AddBranches-main-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 20px;
    margin-top: 30px;
}